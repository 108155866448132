/* Global Styling here */

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

.customScrollbar::-webkit-scrollbar {
  width: 3px;
  background-color: "red";
}

.hideScollbar::-webkit-scrollbar {
  display: none;
}

.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: var(--primary);
}

.absoluteCenter {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .glassBackground {
    -webkit-backdrop-filter: blur(3px);
    backdrop-filter: blur(3px);

    background-color: rgb(206, 198, 183, 0.05);
  }
  .warning {
    display: none;
  }
}

.slick-slide {
  margin: 0 27px;
  background: red;
}
